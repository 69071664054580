import React, {Component} from 'react';

export default function ClientFooter () {
    return (
        <>
            <footer className="rts-footer site-footer-four">
                <div className="container d-block position-relative z-1">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 rts-footer__widget--column">
                            <div className="rts-footer__widget footer__widget w-230">
                                <a href="#" className="footer__logo">
                                    <img src="assets/CImages/logo/footer__four__logo.svg" alt=""/>
                                </a>
                                <p className="brand-desc address">1811 Silverside Rd, Wilmington <br/> DE 19810, USA</p>
                                <div className="separator site-default-border"></div>

                                <div className="contact-method">
                                    <a href="tell:121"><span><i className="fa-regular fa-phone"></i></span>+806 (000) 88 99</a>
                                    <a href="mailto:contact@reactheme.com"><span><i className="fa-light fa-envelope"></i></span>info@hostie.com</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 rts-footer__widget--column">
                            <div className="rts-footer__widget footer__widget extra-padding">
                                <h5 className="widget-title">Company</h5>
                                <div className="rts-footer__widget--menu ">
                                    <ul>
                                        <li><a href="about.html">About Us</a></li>
                                        <li><a href="blog.html">News Feed</a></li>
                                        <li><a href="contact.html">Contact</a></li>
                                        <li><a href="affiliate.html">Affiliate Program</a></li>
                                        <li><a href="technology.html">Our Technology</a></li>
                                        <li><a href="knowledgebase.html">Knowledgebase</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-sm-4 rts-footer__widget--column">
                            <div className="rts-footer__widget footer__widget extra-padding">
                                <h5 className="widget-title">Feature</h5>
                                <div className="rts-footer__widget--menu ">
                                    <ul>
                                        <li><a href="domain-checker.html">Domain Checker</a></li>
                                        <li><a href="domain-transfer.html">Domain Transfer</a></li>
                                        <li><a href="domain-registration.html">Domain Registration</a></li>
                                        <li><a href="data-centers.html">Data Centers</a></li>
                                        <li><a href="whois.html">Whois</a></li>
                                        <li><a href="support.html">Support</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-4 rts-footer__widget--column no-margin xs-margin">
                            <div className="rts-footer__widget footer__widget">
                                <h5 className="widget-title">Hosting</h5>
                                <div className="rts-footer__widget--menu">
                                    <ul>
                                        <li><a href="shared-hosting.html">Shared Hosting</a></li>
                                        <li><a href="wordpress-hosting.html">Wordpress Hosting</a></li>
                                        <li><a href="vps-hosting.html">VPS Hosting</a></li>
                                        <li><a href="reseller-hosting.html">Reseller Hosting</a></li>
                                        <li><a href="dedicated-hosting.html">Dedicated Hosting</a></li>
                                        <li><a href="cloud-hosting.html">Cloud Hosting</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 rts-footer__widget--column no-margin">
                            <div className="rts-footer__widget footer__widget">
                                <h5 className="widget-title">Join Our Newsletter</h5>
                                <p>We'll send you news and offers.</p>
                                <form action="#" className="newsletter">
                                    <input type="email" name="email" placeholder="Enter mail" required/>
                                    <span className="icon"><i className="fa-regular fa-envelope-open"></i></span>
                                    <button type="submit"><i className="fa-regular fa-arrow-right"></i></button>
                                </form>
                                <div className="social__media">
                                    <h5>social media</h5>
                                    <div className="social__media--list">
                                        <a href="#" className="media"><i className="fa-brands fa-facebook-f"></i></a>
                                        <a href="#" className="media"><i className="fa-brands fa-instagram"></i></a>
                                        <a href="#" className="media"><i className="fa-brands fa-linkedin"></i></a>
                                        <a href="#" className="media"><i className="fa-brands fa-x-twitter"></i></a>
                                        <a href="#" className="media"><i className="fa-brands fa-behance"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


            <div className="rts-footer__copyright-two style-four m-0">
                <div className="container d-block">
                    <div className="row">
                        <div className="rts-footer__copyright-two__wrapper">
                            <p className="copyright">&copy; Copyright 2024. All Rights Reserved. PowerITBD</p>
                            <div className="payment__method">
                                <ul>
                                    <li><img src="assets/CImages/payment/visa.svg" alt=""/></li>
                                    <li><img src="assets/CImages/payment/master-card.svg" alt=""/></li>
                                    <li><img src="assets/CImages/payment/paypal.svg" alt=""/></li>
                                    <li><img src="assets/CImages/payment/american-express.svg" alt=""/></li>
                                    <li><img src="assets/CImages/payment/wise.svg" alt=""/></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
